<template>
  <v-app>
    <div>
      <b-form @submit.stop.prevent="formOnsubmit">
        <!-- client_id -->
        <b-form-group
          id="input-group-phone"
          label="Client ID:"
          label-for="input-phone"
        >
          <b-form-input
            id="input-phone"
            v-model="form.client_id"
            type="text"
            placeholder="Masukkan client ID"
          >
          </b-form-input>
          <small class="text-danger">{{ error.client_id }}</small>
        </b-form-group>

        <!-- secret client -->
        <b-form-group
          id="input-group-phone"
          label="Secret key:"
          label-for="input-phone"
        >
          <b-form-input
            id="input-phone"
            v-model="form.client_secret"
            type="text"
            placeholder="Masukkan secret key"
          >
          </b-form-input>
          <small class="text-danger">{{ error.client_secret }}</small>
        </b-form-group>

        <!-- Input Quantity -->
        <b-form-group
          id="input-group-organization_id"
          label="Organization ID:"
          label-for="input-organization_id"
        >
          <b-form-input
            id="input-organization_id"
            v-model="form.organization_id"
            placeholder="Masukkan id organisasi"
          >
          </b-form-input>
          <small class="text-danger">{{ error.organization_id }}</small>
        </b-form-group>

        <b-button type="submit" variant="primary">Simpan</b-button>
        <b-button
          type="button"
          variant="success"
          class="ml-2"
          @click="testConnection()"
          >Test Koneksi</b-button
        >
      </b-form>
    </div>
  </v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {
  setTokenBridge,
  setTokenSatuSehat,
  setRefreshTokenBridge,
  setSsOrganizationId,
  setSsLocationDefault,
  getConfiguration,
} from "@/core/services/jwt.service.js";
import {
  UPDATE_SS_CLIENT_ID,
  UPDATE_SS_CLIENT_SECRET,
  UPDATE_SS_ORGANIZATION_ID,
  UPDATE_SS_ACCESS_TOKEN,
} from "@/core/services/store/tokenstore.module";

import ApiService from "@/core/services/api.service.js";

export default {
  props: {
    form: Object,
    route: String,
  },

  data() {
    return {
      error: {
        client_id: null,
        client_secret: null,
        organization_id: null,
      },
    };
  },

  methods: {
    testConnection() {
      SwalLoading.fire();
      let login_data_api_bridge = {
        username: "medhop@wellmagic.id",
        password: "wm547u53h47",
        grant_type: "password",
        scope: "*",
        ss_client_id: this.form.client_id,
        ss_client_secret: this.form.client_secret,
      };

      ApiService.bridge
        .post("oauth/token", login_data_api_bridge)
        .then((response) => {
          let data = response.data;
          setTokenBridge(data.bridge.access_token);
          setRefreshTokenBridge(data.bridge.refresh_token);
          setTokenSatuSehat(data.satusehat.access_token);
          setSsOrganizationId(this.form.organization_id);
          setSsLocationDefault(this.form.location_default);
          this.$store.dispatch(
            UPDATE_SS_ACCESS_TOKEN,
            data.satusehat.access_token
          );
          ApiService.setHeaderBridge(data.bridge.access_token);
          SwalLoading.close();

          Swal.fire({
            title: "Berhasil",
            text: "Berhasil koneksi ke satu sehat",
            icon: "success",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((err) => {
          SwalLoading.close();
          Swal.fire({
            title: "Gagal",
            text: "Gagal koneksi ke satu sehat. Periksa kembali Client ID atau Secret Key",
            icon: "error",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        });
    },
    async formOnsubmit() {
      // Make Request
      let response = await module.submit(this.form, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        let login_data_api_bridge = {
          username: "medhop@wellmagic.id",
          password: "wm547u53h47",
          grant_type: "password",
          scope: "*",
          ss_client_id: this.form.client_id,
          ss_client_secret: this.form.client_secret,
        };

        ApiService.bridge
          .post("oauth/token", login_data_api_bridge)
          .then((response) => {
            let data = response.data;
            setTokenBridge(data.bridge.access_token);
            setRefreshTokenBridge(data.bridge.refresh_token);
            setTokenSatuSehat(data.satusehat.access_token);
            setSsOrganizationId(this.form.organization_id);
            setSsLocationDefault(this.form.location_default);
            this.$store.dispatch(
              UPDATE_SS_ACCESS_TOKEN,
              data.satusehat.access_token
            );
            ApiService.setHeaderBridge(data.bridge.access_token);
          });

        let form = {
          client_id: this.form.client_id,
          client_secret: this.form.client_secret,
          organization_id: this.form.organization_id,
          satu_sehat_clinic_id: getConfiguration().satu_sehat_clinic_id,
        };
        ApiService.bridge.post("access-ss-update", form).then((response) => {
        });
      }
    },
  },

  mounted() {},
};
</script>

<style>
</style>